import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_cost_form_jiulei',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'parentSubjectsCode') {
        v.restful = '/tpm/tpmBudgetSubjectsController/list';
        v.optionsKey = { label: 'budgetSubjectsName', value: 'budgetSubjectsCode' };
        v.restfulParams = {
          enableStatus: '009',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmBudgetSubjectsController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              this.setValue(res.result);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmBudgetSubjectsController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmBudgetSubjectsController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
